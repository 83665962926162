import type { ExtendedGetResult } from "@synerai/react-fingerprint";

import type { ApplauseEvent } from "@/api/Applause";
import { LV1_HEALTH_FACTORS } from "@/constants/level1hf";
import { getPastDateString } from "@/util/dateCalculations";
import { initialTourObject } from "@/util/tourObject";

import type {
  ArticleSliceModel,
  CompanyProfileSliceModel,
  HomeSliceModel,
  LeaderboardSliceModel,
  PortfolioSliceModel,
  ProductTourSliceModel,
  SectorSliceModel,
  SettingsSliceModel,
  SiteSliceModel,
  SnapshotSliceModel,
  SubSliceModel,
  UserSliceModel,
} from "./redux";

export const articleSliceInitialState: ArticleSliceModel = {
  companyDescriptionLoading: true,
  historicalNarratives: [],
  historicalNarrativesLoading: true,
  topNarrativeSummaries: {
    TopPrimary: [],
    TopSecondary: [],
    TopWeekly: [],
    Trending: [],
  },
  topNarrativeSummariesLoading: true,
};

export const companyProfileSliceInitialState = {
  company: {
    Id: "",
    IsDelisted: false,
    IsPennyStock: false,
    Name: "",
    Sector: "",
    Ticker: "",
  },
  healthFactors: [],
  lite_company: {
    id: "",
    is_bookmarked: false,
    is_public: false,
    name: "",
    sector: "",
    ticker: "",
  },
  news: {
    articles: [],
    date: {
      MaxDate: "",
      MinDate: "",
    },
    healthFactorsFilter: LV1_HEALTH_FACTORS,
    pagination: {
      PageCount: 0,
      PageIndex: 1,
    },
  },
  predictionData: [],
  selectedHFLegend: LV1_HEALTH_FACTORS,
  selectedIssue: {
    IsIssueFromOverview: false,
    articlesCount: 0,
    maxDate: "",
    minDate: "",
    sentenceCount: 0,
  },
  selectedIssueData: {
    Name: "",
    Polarity: "",
  },
  selectedTopicDateData: [],
  showTopicsToggle: true,
} as CompanyProfileSliceModel;

export const makeCompanyProfileSliceInitialState = (date: Date) =>
  ({
    ...companyProfileSliceInitialState,
    news: {
      ...companyProfileSliceInitialState.news,
      date: {
        MaxDate: getPastDateString(date),
        MinDate: getPastDateString(date, 180),
      },
    },
    selectedIssue: {
      ...companyProfileSliceInitialState.selectedIssue,
      maxDate: getPastDateString(date),
      minDate: getPastDateString(date, 7),
    },
  } as CompanyProfileSliceModel);

export const homeSliceInitialState: HomeSliceModel = {
  dateSelected: 7,
  stockRangeFilter: {},
};

export const leaderboardSliceInitialState: LeaderboardSliceModel = {
  dateSelected: 5,
};

export const portfolioSliceInitialState: PortfolioSliceModel = {
  HfFilter: {},
  actionFilter: {
    Action: ["Buy +", "Buy -", "Sell +", "Sell -", "Watch +", "Watch -"],
    Horizon: "1 week",
  },
  companyAddedToPortfolio: 0,
  companyList: [],
  companyListTabIndex: 0,
  pinnedPortfolio: {},
  portfolioCompanies: [],
  portfolios: [],
  selectedPortfolio: {},
};

export const sectorSliceInitialState: SectorSliceModel = {
  HfFilter: {},
  actionFilter: {
    Action: ["Buy +", "Buy -", "Sell +", "Sell -", "Watch +", "Watch -"],
    Horizon: "1 week",
  },
  companyListTabIndex: 0,
  healthFactors: [],
  healthFactorsFilter: LV1_HEALTH_FACTORS,
  sectorCompanies: [],
  sectorRankedPredictions: [],
  sliderValue: 1,
};

export const settingsSliceInitialState: SettingsSliceModel = {
  LeaderboardModalShown: false,
  SettingsLoaded: false,
};

export const siteSliceInitialState: SiteSliceModel = {
  applauseLogs: [],
  authLoaded: false,
  axiosAuthReady: false,
  axiosReady: false,
  cookiesReady: false,
  devMode: false,
  deviceData: {
    data: {} as ExtendedGetResult,
    deviceId: undefined,
    isLoading: true,
  },
  fcReady: [],
  fcRequested: [],
  loginUserType: "CustomerWithEmail",
  trackingProps: {
    page: "",
    path: "",
    prevPath: "",
    query: "",
    trackUrl: "" as ApplauseEvent,
  },
};

export const snapshotSliceInitialState: SnapshotSliceModel = {
  premiumUpsellEnabled: false,
};

export const tourSliceInitialState: ProductTourSliceModel = {
  ProductTour: initialTourObject,
};

export const subSliceInitialState: SubSliceModel = {};

export const userSliceInitialState: UserSliceModel = {
  activeSub: false,
  city: "",
  country: "",
  email_address: "",
  first_name: "",
  id: "",
  isStaff: false,
  last_name: "",
  line1: "",
  line2: "",
  line3: "",
  login_provider_name: "",
  phone_number: "",
  profileCompleted: false,
  profileLoaded: false,
  profileUpdateInProgress: false,
  public_id: "",
  state: "",
  stateAsyncLoaded: false,
  subLoaded: false,
  tos_effective_date: "",
  tos_required: false,
  validatedAccount: false,
  zipcode: "",
};
