import { UTCDate } from "@date-fns/utc";
import { addDays, format, subDays, subHours } from "date-fns";

// For EST (UTC -5) date calculation, common for all
const getCurrentDate = (date: Date) => subHours(new UTCDate(date), 5);

export const getPastDate = (date: Date, noOfDays: number = 0) =>
  subDays(getCurrentDate(date), noOfDays);

export const getPastDateString = (date: Date, noOfDays: number = 0) => {
  return format(getPastDate(date, noOfDays), "yyyy-MM-dd");
};

export const getFutureDate = (date: Date, noOfDays: number = 0) =>
  addDays(getCurrentDate(date), noOfDays);

export const getFutureDateString = (date: Date, noOfDays: number = 0) => {
  return format(getFutureDate(date, noOfDays), "yyyy-MM-dd");
};

export const getDateSeconds = () => {
  const startDate = new Date("2022-01-01");
  const endDate = new Date();
  const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
  return Math.abs(seconds);
};
